import { HttpErrorResponse } from '@angular/common/http';
import { plannedMaintenance } from '@core/models/planned-maintenance';

export class MaintenanceHelper {
  static isMaintenanceResponse(response: HttpErrorResponse): boolean {
    return (
      (response.status === 502 || response.status === 503) &&
      response.error.error === plannedMaintenance
    );
  }
}
