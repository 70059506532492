import { inject, Injectable } from '@angular/core';
import { catchError, combineLatest, Observable, of, retry, switchMap, tap, throwError } from 'rxjs';
import { FfNgxAuthService, FfNgxModalRef, FfNgxModalService, FfNgxRequestService } from '@fagforbundet/ngx-components';
import { map, take } from 'rxjs/operators';
import { membershipApiConfig } from '@config/apis/membership/membership-api.config';
import {
  getIsMemberZodSchema,
} from '@core/models/zod-schemas/membership-api/get-is-member/v1/get-is-member.zod-schema';
import { proxyApiConfig } from '@config/apis/proxy/proxy-api.config';
import {
  getIsEmployeeZodSchema,
} from '@core/models/zod-schemas/proxy-api/get-is-employee/v1/get-is-employee.zod-schema';
import { PermissionStatus } from '@core/enums/permission-status.enum';
import { Router } from '@angular/router';
import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { MaintenanceHelper } from '@core/helpers/maintenance.helper';
import { SKIP_MAINTENANCE_INTERCEPTOR } from '@core/interceptors/maintenance.interceptor';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  readonly #authService = inject(FfNgxAuthService);
  readonly #modalService = inject(FfNgxModalService);
  readonly #requestService = inject(FfNgxRequestService);
  readonly #router = inject(Router);
  #modalRef: FfNgxModalRef;

  handlePermission(status: PermissionStatus): boolean {
    switch (status) {
      case PermissionStatus.ACCESS:
        return true;
      case PermissionStatus.MAINTENANCE:
        this.#router.navigate(['/dashboard']).then(() => {
          this.showAccessModal(
            'Planlagt vedlikehold',
            `<p>Denne modulen er under planlagt vedlikehold. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/" class="ff-link">kontakte brukerstøtte her.</a></p>`,
          );
        });

        return false;
      case PermissionStatus.NO_ACCESS:
        this.showAccessModal(
          'Mangler tilgang',
          `<p>Du mangler tilgang til dette verktøyet. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/" class="ff-link">kontakte brukerstøtte her.</a></p>`,
        );

        return false;
      case PermissionStatus.NOT_EMPLOYEE_OR_MEMBER:
        return false;
      case PermissionStatus.UNDER_DEVELOPMENT:
        this.showAccessModal(
          'Under utvikling',
          `<p>Denne modulen er under utvikling. </p><p>For avklaring og spørsmål kan du <a href="https://kontakt.fagforbundet.no/" class="ff-link">kontakte brukerstøtte her.</a></p>`,
        );

        return false;
      case PermissionStatus.UNRESOLVED:
      default:
        this.showAccessModal(
          'Feil oppstod',
          `<p>Vi fikk dessverre ikke sjekket om du har tilgang til denne modulen.</p><p>Prøv igjen, og om du fremdeles opplever problemer kan du <a href="https://kontakt.fagforbundet.no/" class="ff-link">kontakte brukerstøtte her.</a></p>`,
        );

        return false;
    }
  }

  isEmployee(): Observable<PermissionStatus> {
    return this.#authService.getSelf().pipe(
      take(1),
      switchMap((self) => {
        return this.#requestService.get(
          {
            api: proxyApiConfig,
            endpoint: proxyApiConfig.endpoints.GET_IS_EMPLOYEE,
            endpointVars: {
              userUuid: self!.sub,
            },
          },
          { shouldCache: true },
          {
            context: (new HttpContext()).set(SKIP_MAINTENANCE_INTERCEPTOR, true),
          },
        );
      }),
      map((r) => {
        return getIsEmployeeZodSchema.parse(r.responseBody);
      }),
      map((r: { isEmployee: boolean }) => {
        return r.isEmployee
          ? PermissionStatus.ACCESS
          : PermissionStatus.NO_ACCESS;
      }),
      catchError((e: HttpErrorResponse) => {
        return MaintenanceHelper.isMaintenanceResponse(e)
          ? of(PermissionStatus.MAINTENANCE)
          : of(PermissionStatus.UNRESOLVED);
      }),
    );
  }

  isEmployeeOrMember(): Observable<boolean> {
    return combineLatest([this.isEmployee(), this.isMember()]).pipe(
      map(([isEmployeeStatus, isMemberStatus]) => {
        return (
          isEmployeeStatus === PermissionStatus.ACCESS ||
          isMemberStatus === PermissionStatus.ACCESS
        );
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  isMember(): Observable<PermissionStatus> {
    return this.#requestService
      .get(
        {
          api: membershipApiConfig,
          endpoint: membershipApiConfig.endpoints.GET_IS_MEMBER,
        },
        { shouldCache: true },
        {
          context: (new HttpContext()).set(SKIP_MAINTENANCE_INTERCEPTOR, true),
        },
      )
      .pipe(
        map((r) => {
          return getIsMemberZodSchema.parse(r.responseBody);
        }),
        map((r: { isMember: boolean }) => {
          return r.isMember
            ? PermissionStatus.ACCESS
            : PermissionStatus.NO_ACCESS;
        }),
        catchError((e: HttpErrorResponse) => {
          return MaintenanceHelper.isMaintenanceResponse(e)
            ? of(PermissionStatus.MAINTENANCE)
            : of(PermissionStatus.UNRESOLVED);
        }),
      );
  }

  showAccessModal(title: string, bodyHtml: string): void {
    if (this.#modalRef) {
      this.#modalRef.close();
    }

    this.#modalRef = this.#modalService.openTextModal({
      title,
      bodyHtml,
    });
  }
}
